import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SeoService } from '@core/services/seo.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SeoGuard {
	constructor(private readonly seoService: SeoService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.seoService.setSeo({
			title: route.data['title'],
			description: route.data['description'],
			includeTitleSuffix: route.data['includeTitleSuffix'],
			url: `${environment.appUrl}${state.url}`,
		});

		return true;
	}
}
